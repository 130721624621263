var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "height": "auto",
      "min-height": ['unset', '100vh']
    }
  }, [_c('c-box', {
    attrs: {
      "margin": "auto",
      "width": "100%",
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "height": "100vh"
    }
  }, [_c('iframe', {
    staticStyle: {
      "display": "block",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "src": _vm.getIframeLink,
      "frameborder": "0"
    }
  })])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }